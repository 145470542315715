import React, { useEffect, useRef, useState } from "react";
import Registration from "../auth/RegistrationForm";
import Login from "../auth/LoginForm";
import TextDropdown from "../../components/common/TextDropdown.jsx";
import { useLoggedIn } from "../../hooks/loggedin-hook";
import { useNavigate } from "react-router-dom";
import { useStoryState } from "../../hooks/storyState-hook";


export default function Home() {
  const { loggedIn } = useLoggedIn();
  const [registered, setRegistered] = useState(localStorage.getItem('hasRegistered') === "true")
  const [continueTo, setContinueTo] = useState(null)
  const navigate = useNavigate();
  const { storyState } = useStoryState();

  useEffect(() => {
    if (storyState) {
      if (storyState.bookmarks.length > 0) {
        const { act, scene, section } = storyState.bookmarks[storyState.bookmarks.length - 1]
        setContinueTo({ act, scene, section })
      }
    }
  }, [storyState])


  useEffect(() => {
    localStorage.setItem('hasRegistered', registered)
  }, [registered])


  function scrollToElement(e) {
    e.preventDefault()
    console.log(registered, loggedIn?.email)
    if (registered && loggedIn?.email) {
      document.getElementById('get-started').scrollIntoView()
    }
    else {
      document.getElementById('get-registered').scrollIntoView()
    }
  }

  return (
    <>
      <section id="slider" className="slider-element min-vh-100 dark include-header">
        <div className="slider-inner page-title-parallax">
          <div className="video-overlay">
            <div className="vertical-middle center">
              <div className="container">
                <a href="/" data-scrollto="#1" data-easing="easeInOutExpo" data-speed="1250" data-offset="70">
                  <img src="https://fatalfire.nyc3.cdn.digitaloceanspaces.com/Fatal%20Fire%20Texture_VJ.png" alt="Fatal Fire Logo" width="50%" />
                </a>
                <br />
                <button onClick={(e) => scrollToElement(e)} className="button button-border button-light button-rounded">
                  Begin
                </button>

              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="content">
        <div className="content-wrap py-0">
          <div id="section-about" className="center page-section">
            <div className="container clearfix">
              <blockquote>
                <h2 id="1">Fatal Fire is an interactive musical audioplay about Christianity, creativity, and meaning.</h2>
              </blockquote>
              <p id="get-registered">
                As you read this story and press various audio buttons you’ll <em>unlock</em> the soundtrack, which you can replay later from the <a href="music">music </a>
                page. Please make an account so you can save your progress as you continue—we take privacy seriously.
                <TextDropdown
                  markup={`<div>
                  <br />
                  <h4>Our Simple Privacy Policy</h4>
                  The only information we store in our database is the email address and password you specify to log in and your progress—that is, whatever chapters, music, etc. you’ve unlocked as you read the story.
                  We will never share or sell your data, we have no interest in bothering you, and we will likely never send you another email once you complete registration. <br /><br />
                  Read our full <b>Privacy Policy</b> <a href="privacy">here</a>.
                </div>`}
                />
              </p>



              {!loggedIn?.email ? (
                <>
                  <h4 >Get Started...</h4>
                  {registered &&
                    <a className="button button-border button-light button-rounded"
                      onClick={() => {
                        localStorage.removeItem('hasRegistered')
                        setRegistered(false)
                      }}>Registration</a>}

                  {registered
                    ?
                    <Login />
                    :
                    <Registration setRegistered={setRegistered} />}

                </>
              ) : null}



              <p>
                You can likely get a sense of whether or not reading this story is for you within the first few scenes. If you’d prefer to listen to the three <a href="music">main story albums</a> right now instead of unlocking them as you progress through the story, you can pay <em>anything</em> <a href="unlock">here</a> to unlock those immediately—half of whatever amount you give will go to charity. <br />
                <br />
                We also recommend that you use good headphones as you proceed. We test this site with {" "}
                <a href="https://duckduckgo.com/?q=beyerdynamic+DT+770+Pro+32+Ohm+Headphones" target="_blank" rel="noreferrer">
                  these
                </a>{" "}.
              </p>
              <br />
              <div id="get-started" className="center">
                {loggedIn?.email ?
                  <button className="button button-border button-light button-rounded" onClick={() => navigate(`/story/${continueTo?.act || 1}/${continueTo?.scene || 0}/${continueTo?.section || 0}`)}>
                    <i className="icon-arrow-right"></i>{continueTo?.act ? `Act ${continueTo?.act}, Scene ${continueTo?.scene}, Section ${continueTo?.section}` : 'Continue to Act 1'}
                  </button>
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
